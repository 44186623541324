<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0.6" color="white">
        <v-progress-circular color="gifty"
                             indeterminate
                             size="50"
        ></v-progress-circular>
      </v-overlay>

      <div class="main">
        <v-row justify="center">
          <v-col cols="12" lg="10">


            <v-card class="rounded-lg shadow">

              <v-card-title>
                <span v-if="!$route.params.id"> Ajouter un service</span>
                <span v-if="$route.params.id"> Modifier une service</span>
                <v-spacer/>
                <v-btn color="primary"
                       text
                       small
                       to="/supplier-manager-services">
                  <v-icon left>mdi-arrow-left</v-icon>
                  Retour
                </v-btn>
              </v-card-title>

              <v-divider/>

              <v-card-text class="pa-4 pa-lg-8">

                <v-row>


                  <v-col cols="6">
                    <v-text-field outlined
                                  v-model="form.name"
                                  :error-messages="errors.name"
                                  dense
                                  hide-details="auto"
                                  label="Désignation en français *"></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field outlined
                                  style="direction: rtl"
                                  v-model="form.name_ar"
                                  :error-messages="errors.name_ar"
                                  dense
                                  hide-details="auto"
                                  label="Désignation en arabe *"></v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-textarea rows="2"
                                outlined
                                v-model="form.short_description"
                                :error-messages="errors.short_description"
                                dense
                                hide-details="auto"
                                label="Brève description en français *"></v-textarea>
                  </v-col>

                  <v-col cols="6">
                    <v-textarea rows="2"
                                outlined
                                style="direction: rtl"
                                v-model="form.short_description_ar"
                                :error-messages="errors.short_description_ar"
                                dense
                                hide-details="auto"
                                label="Brève description en arabe *"></v-textarea>
                  </v-col>

                  <v-col cols="6">
                    <p>
                      Grande description en français *
                    </p>
                    <ckeditor :config="{language: 'fr'}" v-model="form.description"/>

                    <small v-if="errors && errors.description" class="red--text">
                      {{ errors.description.toString() }}
                    </small>
                  </v-col>

                  <v-col cols="6">
                    <p>
                      Grande description en arabe *
                    </p>
                    <ckeditor :config="{language: 'fr'}" v-model="form.description_ar"/>

                    <small v-if="errors && errors.description_ar" class="red--text">
                      {{ errors.description_ar.toString() }}
                    </small>
                  </v-col>

                  <v-col cols="6">
                    <p>
                      Description de l'utilisation en français *
                    </p>
                    <ckeditor :config="{language: 'fr'}" v-model="form.usage"/>
                    <small v-if="errors && errors.usage" class="red--text">
                      {{ errors.usage.toString() }}
                    </small>
                  </v-col>

                  <v-col cols="6">
                    <p>
                      Description de l'utilisation en arabe *
                    </p>
                    <ckeditor :config="{language: 'fr'}" v-model="form.usage_ar"/>
                    <small v-if="errors && errors.usage_ar" class="red--text">
                      {{ errors.usage_ar.toString() }}
                    </small>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field outlined
                                  v-model="form.url"
                                  :error-messages="errors.url"
                                  dense
                                  hide-details="auto"
                                  label="URL du service (Site web, E-commerce...)"></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field outlined
                                  v-model="form.external_url"
                                  :error-messages="errors.external_url"
                                  dense
                                  hide-details="auto"
                                  label="URL externe(vidéo,landing page...)"></v-text-field>
                  </v-col>

                  <v-col cols="6">

                    <v-select outlined
                              :items="types"
                              v-model="form.service_type_id"
                              :error-messages="errors.service_type_id"
                              dense
                              item-text="name"
                              item-value="id"
                              hide-details="auto"
                              label="Type de service *"></v-select>
                  </v-col>

                  <v-col cols="6">

                    <v-select v-model="form.card_type"
                              :error-messages="errors.card_type"
                              :items="cardTypes"
                              dense
                              outlined
                              :disabled="!(typeName === 'Carte')"
                              :filled="!(typeName === 'Carte')"
                              item-text="name"
                              item-value="id"
                              hide-details="auto"
                              label="Type du Carte *"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12"
                         v-if="(typeName === 'Refill')">
                    <v-checkbox v-model="form.refill_with_voucher"
                                class="mt-0 pt-0"
                                :error-messages="errors.refill_with_voucher"
                                hide-details="auto"
                                label="Refill avec des vouchers ?"/>
                  </v-col>


                  <v-col cols="6">
                    <v-select outlined
                              :items="categories"
                              v-model="form.categories"
                              :error-messages="errors.categories"
                              dense
                              multiple
                              item-text="name"
                              item-value="id"
                              hide-details="auto"
                              label="Catégories"></v-select>
                  </v-col>

                  <v-col cols="6">
                    <v-select
                        :disabled="!((typeName === 'Voucher') || (typeName === 'Refill') &&  form.refill_with_voucher)"
                        :filled="!((typeName === 'Voucher') || (typeName === 'Refill') &&  form.refill_with_voucher)"
                        outlined
                        v-model="form.supplier_id"
                        :error-messages="errors.supplier_id"
                        item-text="name"
                        item-value="id"
                        :items="suppliers"
                        chips
                        clearable
                        deletable-chips
                        dense
                        hide-details="auto"
                        label="Sélectionner le fournisseur *"
                        small-chips>
                    </v-select>
                  </v-col>



                  <v-col cols="6" v-show="typeName === 'Voucher' && form.with_build_vouchers">
                    <v-checkbox class="mt-0 pt-0"
                                hide-details="auto"
                                v-model="form.with_dynamic_api"
                                label="Avec API?"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="6">
                    <v-file-input ref="image"
                                  :error-messages="errors.image"
                                  accept="image/png, image/jpeg, image/jpg, image/svg, .svg"
                                  chips
                                  small-chips
                                  outlined
                                  dense
                                  hide-details="auto"
                                  label="Logo ou image *"
                                  show-size
                                  @change="uploadFile($event,'image')"/>

                    <div v-if="form.image" class="mt-2 rounded-lg grey lighten-5">
                      <div class="pa-3 d-flex align-center justify-space-between">
                        <v-avatar rounded size="40">
                          <v-img contain :src="fileUrl + form.image"></v-img>
                        </v-avatar>
                        <v-spacer></v-spacer>
                        <v-icon color="red" @click="form.image = null">mdi-close</v-icon>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="6">
                    <v-file-input ref="background_image"
                                  :error-messages="errors.background_image"
                                  accept="image/png, image/jpeg, image/jpg, image/svg, .svg"
                                  chips
                                  small-chips
                                  outlined
                                  dense
                                  hide-details="auto"
                                  label="Image de fond *"
                                  show-size
                                  @change="uploadFile($event,'background_image')"/>

                    <div v-if="form.background_image" class="mt-2 rounded-lg grey lighten-5">
                      <div class="pa-3 d-flex align-center justify-space-between">
                        <v-avatar rounded size="40">
                          <v-img contain :src="fileUrl + form.background_image"></v-img>
                        </v-avatar>
                        <v-spacer></v-spacer>
                        <v-icon color="red" @click="form.background_image = null">mdi-close</v-icon>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field label="Couleur de l'arrière plan *"
                                  type="color"
                                  :error-messages="errors.background_color"
                                  v-model="form.background_color"
                                  hide-details="auto"
                                  outlined
                                  dense>
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-text-field label="Couleur du Texte *"
                                  type="color"
                                  :error-messages="errors.text_color"
                                  v-model="form.text_color"
                                  hide-details="auto"
                                  outlined
                                  dense>
                    </v-text-field>
                  </v-col>

                  <v-col cols="6">
                    <v-file-input ref="gallery"
                                  multiple
                                  :error-messages="errors.gallery"
                                  accept="image/png, image/jpeg, image/jpg, image/svg, .svg"
                                  chips
                                  outlined
                                  small-chips
                                  dense
                                  hide-details="auto"
                                  label="Galeries * (Sélectionner multiple)"
                                  show-size
                                  @change="uploadFile($event,'gallery')"/>

                    <div v-if="form.gallery && form.gallery.length" class="mt-2 rounded-lg grey lighten-5">
                      <div class="pa-3 d-flex align-center justify-space-between" v-for="(gallery,i) in form.gallery"
                           :key="i">
                        <v-avatar rounded size="40">
                          <v-img contain :src="fileUrl + gallery"></v-img>
                        </v-avatar>
                        <v-spacer></v-spacer>
                        <v-icon color="red" @click="form.gallery.splice(i,1)">mdi-close</v-icon>
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <p>
                      Contact & Réseaux Sociaux :
                    </p>

                    <v-simple-table class="table-border rounded-lg">
                      <template v-slot:default>
                        <thead>
                        <tr>
                          <th>Nom</th>
                          <th>Valeur</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item,i) in form.contact_details" :key="i">
                          <td width="40%">
                            <v-select dense
                                      label="Nom"
                                      outlined
                                      hide-details
                                      :items='["facebook", "instagram", "youTube", "linkedIn", "x", "threads","link", "phone"]'
                                      v-model="item.name"></v-select>
                          </td>
                          <td>
                            <v-text-field label="Valeur" outlined dense hide-details
                                          v-model="item.value"></v-text-field>
                          </td>
                          <td>
                            <v-icon color="red" @click="form.contact_details.splice(i,1)">mdi-close</v-icon>
                          </td>
                        </tr>
                        <tr v-if="!form.contact_details.length">
                          <td colspan="3" class="text-center">
                            Aucun enregistrement !
                          </td>
                        </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <div class="text-center mt-3">
                      <v-icon color="gifty" @click="form.contact_details.push({name : '',value : ''})">mdi-plus-circle
                      </v-icon>
                    </div>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox v-model="form.with_build_vouchers"
                                class="mt-0 pt-0"
                                hide-details="auto"
                                label="Avec Génération dynamique"></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox class="mt-0 pt-0"
                                hide-details="auto"
                                v-model="form.with_order"
                                label="Avec Commande"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox class="mt-0 pt-0"
                                hide-details="auto"
                                v-model="form.visible"
                                label="Visible ?"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox class="mt-0 pt-0"
                                hide-details="auto"
                                v-model="form.top_service"
                                label="Top Service ?"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox class="mt-0 pt-0"
                                hide-details="auto"
                                v-model="form.physical_store"
                                label="Stockage physique ?"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12">

                    <v-checkbox v-model="form.is_parent"
                                class="mt-0 pt-0"
                                :hide-details="!!(form.is_parent)"
                                label="Service Parent"/>

                    <v-autocomplete v-if="!form.is_parent"
                                    v-model="form.service_parent_id"
                                    :error-messages="errors.service_parent_id"
                                    :items="services"
                                    outlined
                                    dense
                                    hide-details="auto"
                                    item-text="name"
                                    item-value="id"
                                    label="Veuillez sélectionner le Service parent *"
                    ></v-autocomplete>
                  </v-col>

                  <v-col cols="12">
                    <v-checkbox class="mt-0 pt-0"
                                v-model="form.has_sms"
                                label="A des SMS?"
                    />

                    <v-checkbox class="mt-0 pt-0"
                                v-model="form.has_account"
                                label="A un compte?"
                    />
                    <v-checkbox class="mt-0 pt-0"
                                v-model="form.is_refundable"
                                hide-details
                                label="Remboursable?"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-radio-group label="État du service"
                                   row
                                   mandatory
                                   hide-details="auto"
                                   class="mt-0 pt-0"
                                   :error-messages="errors.service_state_id"
                                   v-model="form.service_state_id">
                      <v-radio v-for="(state,i) in states" :key="i" :value="state.id" :label="state.name"></v-radio>
                    </v-radio-group>
                  </v-col>

                </v-row>

              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="gifty" depressed dark @click="saveService" :loading="btnLoading">
                  <v-icon left>mdi-content-save</v-icon>
                  Enregistrer
                </v-btn>
              </v-card-actions>

            </v-card>

          </v-col>
        </v-row>
      </div>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      overlay: false,
      btnLoading: false,
      form: {

        background_color: "#FFFFFF",
        text_color: "#292727",
        is_parent: true,
        image: null,
        background_image: null,
        gallery: [],

        top_service: false,
        with_order: false,
        physical_store: false,
        with_dynamic_api: false,
        visible: true,
        is_default_pos: false,
        with_build_vouchers: false,
        has_sms: true,
        has_account: true,
        is_refundable: false,
        contact_details: [],
        categories: [],
      },
      errors: {},
      fileUrl: process.env.VUE_APP_FILE_URL,

      types: [],
      states: [],
      categories: [],
      suppliers: [],
      services: [],

    };
  },
  methods: {
    saveService() {
      this.errors = {}
      this.btnLoading = true
      let url = this.$route.params.id ? "v2/supplier-manager/services/" + parseInt(this.$route.params.id) + "/update" : "v2/supplier-manager/services/store"
      HTTP.post(url, this.form).then(() => {
        this.btnLoading = false
        this.$successMessage = "Ce Service a été crée avec succès";
        this.$router.push('/supplier-manager-services')
      }).catch((error) => {
        this.btnLoading = false
        console.error(error);
        this.errors = error.response.data.errors
        this.$errorMessage = "Une erreur s'est produite";
      });
    },
    showService() {
      this.overlay = true
      let url = "v2/supplier-manager/services/" + parseInt(this.$route.params.id) + "/show"
      HTTP.get(url).then((res) => {
        this.overlay = false
        this.form = res.data.data
        this.form.categories = res.data.data && res.data.data.categories && res.data.data.categories.length ? res.data.data.categories.map(el => el.id) : []
        this.form.contact_details = res.data.data && res.data.data.contact_details && res.data.data.contact_details.length ? res.data.data.contact_details : []
      }).catch((error) => {
        this.overlay = false
        console.error(error);
      });
    },

    uploadFile(file, name) {
      const files = Array.isArray(file) ? file : [file];

      if (files && files.length > 0) {
        this.overlay = true;

        Array.from(files).forEach((file) => {
          let formData = new FormData();
          formData.append('file', file);

          HTTP.post("/v2/supplier-manager/services/upload-file", formData)
              .then((res) => {
                this.overlay = false;

                if (name !== 'gallery') {
                  this.form[name] = res.data.data;
                } else {
                  this.form.gallery.push(res.data.data);
                }
              })
              .catch((error) => {
                this.overlay = false;

                if (error.response) {
                  if (error.response.status === 422) {
                    this.$errorMessage = "Veuillez changer l'image, la taille est trop grande";
                  } else {
                    this.$errorMessage = `Erreur: ${error.response.status}`;
                  }
                } else {
                  this.$errorMessage = "Une erreur s'est produite";
                }
                console.error(error);
              });
        });
      }
    },
    getConfig() {
      this.overlay = true
      Promise.all([
        HTTP.get("/V2/categories"),
        HTTP.get("/service-state"),
        HTTP.get("/service-type"),
        HTTP.get("/manager/vouchers/suppliers"),
        HTTP.get("/v2/supplier-manager/services/parents"),
      ]).then((responses) => {
        this.overlay = false
        this.categories = responses[0].data.data;
        this.states = responses[1].data;
        this.types = responses[2].data;
        this.suppliers = responses[3].data;
        this.services = responses[4].data.data;
      }).catch((error) => {
        this.overlay = false
        console.error("Error fetching data:", error);
      });
    },
  },
  created() {
    this.getConfig()
    if (this.$route.params.id) {
      this.showService()
    }
  },
  computed: {
    cardTypes() {
      return [
        {id: "Idoom", name: "Idoom"},
        {id: "4G", name: "4G"},
      ]
    },
    typeName() {
      return this.types.find(el => el.id === this.form.service_type_id) && this.types.find(el => el.id === this.form.service_type_id)['name']
    },

  },

};
</script>

<style>
.cke_notification_warning {
  display: none;
}
</style>
